import './App.css';
import React, { useState, useEffect } from 'react';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
// import GMapPanel from './components/GMapPanel';
import MyMap from './components/MyMap';

function App() {
  const [selectedProvince, setSelectedProvince] = useState('');
  const [coordinates, setCoordinates] = useState(null);
  const [stores, setStores] = useState([]); 
  const [showTimeoutPopup, setShowTimeoutPopup] = useState(false);

  useEffect(() => {
    setInterval(() => {
      setShowTimeoutPopup(true);
  }, 10 * 60 * 1000); // 10 minutes
  }, []);
  
  const handleProvinceChange = (province) => {
    setSelectedProvince(province);
  };

  const handleCoordinatesChange = (coords) => {
    setCoordinates(coords);
  };

  const handleRefresh = () => {
    window.location.reload();
  };
  const handleStoresChange = (stores) => {
    setStores(stores);
  };

  return (
    <div className="App">
      <Header />
      <div className="content">
        <Sidebar onProvinceChange={handleProvinceChange} onCoordinatesChange={handleCoordinatesChange} onStoresChange={handleStoresChange} />
        <MyMap selectedProvince={selectedProvince} coordinates={coordinates} stores={stores} />
      </div>
      {showTimeoutPopup && (
        <>
          <div className="timeout-overlay"></div>
          <div className="timeout-popup">
            <h2>Warning!</h2>
            <p>Your session has been inactive for 10 minutes. Please refresh the page.</p>
            <button onClick={handleRefresh}>Refresh</button>
          </div>
        </>
      )}
      <div className='footer'>
        <p>© 2024 <a href="https://subexpress.ca/" style={{ color: 'black', textDecoration: 'none' }}>Sub Express</a>. All rights reserved.</p>
        <p>Made with ❤️ by <a href="https://www.iorders.ca/" style={{ color: '#009889', textDecoration: 'none' }}>iOrders</a> 2.1.2</p>
      </div>
    </div>
  );
}

export default App;