function getOrderOnlineLink(title) {
  const linkMap = {
    // "p-prairie-donair-airdrie": "https://prairiedonair.gpr.globalpaymentsinc.ca/menu/?deliveryInstructions=&locationId=34174&orderMethod=3&orderType=1&pickupCurbside=false",
    // "p-prairie-donair-crowfoot": "https://prairiedonair.gpr.globalpaymentsinc.ca/menu/?deliveryInstructions=&locationId=34167&orderMethod=3&orderType=1&pickupCurbside=false",
    // "p-prairie-donair-deer-ridge": "https://prairiedonair.gpr.globalpaymentsinc.ca/menu/?deliveryInstructions=&locationId=34164&orderMethod=3&orderType=1&pickupCurbside=false",
    
    "p-subexpress-regina-quance":"N/A",
  };
  
  return linkMap[title] || `https://orders.iorders.online/${title}`;
}

// ... rest of the code remains unchanged
const storeDetails = {
  status: "success",
  data: {
    restaurants: [
      //1. Sub Express Park ST
      {
        profile: {
          id: 124,
          restaurant_name: "Sub Express Park St",
          title: "p-subexpress-regina",
          address: "1055 Park St #103, Regina, SK S4N 5H4",
          phone: "+1 (306) 584-7335",
          available_days: [
            {id: 1746, day: 'SUNDAY', opening_time: '10:00:00', closing_time: '17:00:00', order_mode: 1},
            {id: 1747, day: 'MONDAY', opening_time: '05:30:00', closing_time: '20:00:00', order_mode: 1},
            {id: 1748, day: 'TUESDAY', opening_time: '05:30:00', closing_time: '20:00:00', order_mode: 1},
            {id: 1749, day: 'WEDNESDAY', opening_time: '05:30:00', closing_time: '20:00:00', order_mode: 1},
            {id: 1750, day: 'THURSDAY', opening_time: '05:30:00', closing_time: '20:00:00', order_mode: 1},
            {id: 1751, day: 'FRIDAY', opening_time: '05:30:00', closing_time: '20:00:00', order_mode: 1},
            {id: 1752, day: 'SATURDAY', opening_time: '09:00:00', closing_time: '20:00:00', order_mode: 1}
          ],
          close_restaurent: false,
          lat: "50.462148232756185",
          lng: "-104.56990197095524",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },
      //2. Sub Express Albert ST
      {
        profile: {
          id: 124,
          restaurant_name: "Sub Express Albert St",
          title: "p-subexpress-regina-albert",
          address: "2108 Albert St, Regina, SK S4P 2T9",
          phone: "+1 (306) 517-8090",
          available_days: [
            {id: 1746, day: 'SUNDAY', opening_time: '10:00:00', closing_time: '23:00:00', order_mode: 1},
            {id: 1747, day: 'MONDAY', opening_time: '07:00:00', closing_time: '23:00:00', order_mode: 1},
            {id: 1748, day: 'TUESDAY', opening_time: '07:00:00', closing_time: '23:00:00', order_mode: 1},
            {id: 1749, day: 'WEDNESDAY', opening_time: '07:00:00', closing_time: '23:00:00', order_mode: 1},
            {id: 1750, day: 'THURSDAY', opening_time: '07:00:00', closing_time: '23:00:00', order_mode: 1},
            {id: 1751, day: 'FRIDAY', opening_time: '07:00:00', closing_time: '23:00:00', order_mode: 1},
            {id: 1752, day: 'SATURDAY', opening_time: '09:00:00', closing_time: '23:00:00', order_mode: 1}
          ],
          close_restaurent: false,
          lat: "50.445076342364466",
          lng: "-104.6187159423287",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },
       //3. Sub Express Quance ST
       {
        profile: {
          id: 124,
          restaurant_name: "Sub Express Quance St",
          title: "p-subexpress-regina-quance",
          address: "2577 Quance St, Regina, SK S4V 2Y7",
          phone: "+1 (306) 584-7335",
          available_days: [
            {id: 1746, day: 'SUNDAY', opening_time: '10:00:00', closing_time: '17:00:00', order_mode: 1},
            {id: 1747, day: 'MONDAY', opening_time: '10:00:00', closing_time: '17:00:00', order_mode: 1},
            {id: 1748, day: 'TUESDAY', opening_time: '05:30:00', closing_time: '20:00:00', order_mode: 1},
            {id: 1749, day: 'WEDNESDAY', opening_time: '05:30:00', closing_time: '20:00:00', order_mode: 1},
            {id: 1750, day: 'THURSDAY', opening_time: '05:30:00', closing_time: '20:00:00', order_mode: 1},
            {id: 1751, day: 'FRIDAY', opening_time: '05:30:00', closing_time: '20:00:00', order_mode: 1},
            {id: 1752, day: 'SATURDAY', opening_time: '09:00:00', closing_time: '20:00:00', order_mode: 1}
          ],
          close_restaurent: true,
          lat: "50.44298140775227",
          lng: "-104.54720718465744",
          get order_online_link() {
            return getOrderOnlineLink(this.title);
          },
        }
      },

      
    ]
  },
  message: "successful response"
};

export default storeDetails;
